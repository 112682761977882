import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import { useContext, useEffect, useRef } from 'react';
import { Wizard, useWizard } from 'react-use-wizard';
import { invitationForm } from '../../../internal/utils/InvitationForms.js';
import InvitationContext from '../../context/InvitationContext.js';
import './CommonForm.css';
import { InvitationAPI } from '../../../internal/services/InvitationAPI.js';
const EnterGuestInfoForm = ({ changeActiveForm }) => {
    const { invitation } = useContext(InvitationContext);

    const guestId = invitation.activeGuestId;
    if (guestId === null) {
        changeActiveForm(invitationForm.guestTabForm);
        return null;
    }

    return (
        <div className='formRespondNow'>
            <Wizard footer={<Footer backToGuest={changeActiveForm} />}>
                <UpdateGuestInfoStep />
                <BabyStep />
                <CeremonyStep />
                <CocktailStep />
                <DinnerStep />
                <MealStep />
                <BrunchStep />
                <ValidateStep />
                <ThankYouStep />
            </Wizard>
        </div>
    );
};

const Footer = ({ backToGuest }) => {
    const { previousStep, nextStep, activeStep, stepCount } = useWizard();
    const { t } = useTranslation();

    const { invitation, setInvitation } = useContext(InvitationContext);

    const guestId = invitation.activeGuestId;

    const guestInfo = invitation.guests.find(guest => guest.id === guestId);

    const validate = () => {


        guestInfo.response.validate = true;
        if (guestInfo.firstName === "") {
            guestInfo.firstName = "anonymous";
        }

        if (guestInfo.lastName === "") {
            guestInfo.lastName = "anonymous";
        }

        if (!guestId || guestId.includes("anonymous")) {
            guestInfo.id = undefined;
        }

        var updatedGuests = invitation.guests.map(guest =>
            guest.id === guestId ? guestInfo : guest
        );


        setInvitation({ ...invitation, guests: updatedGuests });
        InvitationAPI.updateInvitation(invitation)
        .then((response) => {
            setInvitation(response);
        })
        .catch((response) => {
            //TODO better alerting
            alert(t("errorContactUs"));
            console.log(response.status, response.statusText);
        });
        nextStep();
    };

    const SwitchCaseButton = () => {
        switch (activeStep) {
            case stepCount - 2: return <Button className="find-guest-button" variant="success" size="md" onClick={() => validate()}>{t("validateButton")}</Button>;
            case stepCount - 1: return <Button className="response-button" variant="success" size="md" onClick={() => backToGuest(invitationForm.guestTabForm)}>{t("backToGuestTabForm")}</Button>;
            case 0: return <Button className="response-button" variant="success" size="md" onClick={() => nextStep()}>{t("nextButton")}</Button>;
            default: return <div className='buttonStep'><Button className="response-button" variant="secondary" size="md" onClick={() => previousStep()}>{t("previousButton")}</Button></div>
        }
    };
    return (
        <SwitchCaseButton />
    );
};

const UpdateGuestInfoStep = () => {
    const { stepCount, goToStep } = useWizard();
    const { t } = useTranslation();
    const { invitation, setInvitation } = useContext(InvitationContext);
    const guestId = invitation.activeGuestId;

    const guestInfo = invitation.guests.find(guest => guest.id === guestId);

    if (guestInfo.response.validate === true) {
        goToStep(stepCount - 2);
    }

    const handleChange = (field, event) => {
        event.target.style.borderColor = "";
        if (field === 'email') {
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailPattern.test(event.target.value)) {
                event.target.style.borderColor = "red";
                return;
            }
        } else if (field === 'phone') {
            const phonePattern = /^\+?[0-9]{10,15}$/;
            if (!phonePattern.test(event.target.value)) {
                event.target.style.borderColor = "red";
                return;
            }
        } else {
            const specialCharPattern = /^[a-zA-Z0-9\s]*$/;
            if (!specialCharPattern.test(event.target.value)) {
                event.target.style.borderColor = "red";
                return;
            }
        }
        guestInfo[field] = event.target.value;
        const updatedGuests = invitation.guests.map(guest =>
            guest.id === guestId ? guestInfo : guest
        );
        setInvitation({ ...invitation, guests: updatedGuests });
    };

    return (
        <div>
            <h2 className='labelTop'>{t("updateGuestInfoLabel")}</h2>
            <Form className="text-center">
                <Form.Group controlId="formFirstName" className="formGroup">
                    <Form.Label>{t("firstNameLabel")}</Form.Label>
                    <Form.Control
                        type="text"
                        value={guestInfo.firstName}
                        onChange={(e) => handleChange('firstName', e)}
                        maxLength={50}
                    />
                </Form.Group>
                <Form.Group controlId="formLastName" className="formGroup">
                    <Form.Label>{t("lastNameLabel")}</Form.Label>
                    <Form.Control
                        type="text"
                        value={guestInfo.lastName}
                        onChange={(e) => handleChange('lastName', e)}
                        maxLength={50}
                    />
                </Form.Group>
                <Form.Group controlId="formEmail" className="formGroup">
                    <Form.Label>{t("emailLabel")}</Form.Label>
                    <Form.Control
                        type="email"
                        onChange={(e) => handleChange('email', e)}
                        maxLength={50}
                    />
                </Form.Group>
                <Form.Group controlId="formPhone" className="formGroup">
                    <Form.Label>{t("phoneLabel")}</Form.Label>
                    <Form.Control
                        type="tel"
                        onChange={(e) => handleChange('phone', e)}
                        maxLength={50}
                    />
                </Form.Group>
            </Form>
        </div>
    );
};

const ThankYouStep = () => {
    const { t } = useTranslation();
    return (
        <div>
            <h2>{t("doneHeader")}</h2>
            <p>{t("thankYouMessage")}</p>
            <p>
                {t("donateMessage")} <a href="https://www.leetchi.com/fr/c/nath-et-med-3000028?utm_source=copylink&utm_medium=social_sharing" target="_blank" rel="noopener noreferrer">{t("contribution.buttonText")}</a>
            </p>
            <p>
                {t("suggestionMessage")}
            </p>
        </div>
    );
};

const BabyStep = () => {
    const { t } = useTranslation();
    const { invitation, setInvitation } = useContext(InvitationContext);
    const { nextStep, goToStep, stepCount } = useWizard();
    const guestId = invitation.activeGuestId;

    const guestInfo = invitation.guests.find(guest => guest.id === guestId);
    if (guestInfo.type !== "baby") {
        nextStep();
    }

    const answer = (response) => {
        guestInfo.response.ceremony = response;
        const updatedGuests = invitation.guests.map(guest =>
            guest.id === guestId ? guestInfo : guest
        );
        setInvitation({ ...invitation, guests: updatedGuests });
        goToStep(stepCount - 2);
    };

    return (
        <div>
            <p className='labelTop'>{t("babyStep.question-p1") + guestInfo.firstName + t("babyStep.question-p2")}</p>
            <div className="buttonGroup">
                <Button className="enterGuestInfo-response-button" onClick={() => answer(true)}>{t("yesButton")}</Button>
                <Button className="enterGuestInfo-response-button" onClick={() => answer(false)}>{t("noButton")}</Button>
            </div>
        </div>
    );
};

const CeremonyStep = () => {
    const { t } = useTranslation();
    const { nextStep } = useWizard();
    const { invitation, setInvitation } = useContext(InvitationContext);
    const guestId = invitation.activeGuestId;

    const guestInfo = invitation.guests.find(guest => guest.id === guestId);
    const answer = (response) => {
        guestInfo.response.ceremony = response;
        const updatedGuests = invitation.guests.map(guest =>
            guest.id === guestId ? guestInfo : guest
        );
        setInvitation({ ...invitation, guests: updatedGuests });
        nextStep();
    };

    return (
        <div>
            <p className='labelTop'>{t("questionCeremony")}</p>
            <div className="buttonGroup">
                <Button className="enterGuestInfo-response-button" onClick={() => answer(true)}>{t("yesButton")}</Button>
                <Button className="enterGuestInfo-response-button" onClick={() => answer(false)}>{t("noButton")}</Button>
            </div>
        </div>
    );
};

const CocktailStep = () => {
    const { t } = useTranslation();
    const { nextStep } = useWizard();
    const { invitation, setInvitation } = useContext(InvitationContext);
    const guestId = invitation.activeGuestId;

    const guestInfo = invitation.guests.find(guest => guest.id === guestId);
    const answer = (response) => {
        guestInfo.response.cocktail = response;
        const updatedGuests = invitation.guests.map(guest =>
            guest.id === guestId ? guestInfo : guest
        );
        setInvitation({ ...invitation, guests: updatedGuests });
        nextStep()
    };

    return (
        <div>
            <p className='labelTop'>{t("questionCocktail")}</p>
            <div className="buttonGroup">
                <Button className="enterGuestInfo-response-button" onClick={() => answer(true)}>{t("yesButton")}</Button>
                <Button className="enterGuestInfo-response-button" onClick={() => answer(false)}>{t("noButton")}</Button>
            </div>
        </div>
    );
};

const DinnerStep = () => {
    const { t } = useTranslation();
    const { nextStep } = useWizard();
    const { invitation, setInvitation } = useContext(InvitationContext);
    const guestId = invitation.activeGuestId;

    const guestInfo = invitation.guests.find(guest => guest.id === guestId);

    if (guestInfo.dinner === false) {
        nextStep();
    }


    const answer = (response) => {
        guestInfo.response.dinner = response;
        const updatedGuests = invitation.guests.map(guest =>
            guest.id === guestId ? guestInfo : guest
        );
        setInvitation({ ...invitation, guests: updatedGuests });
        nextStep();
    };

    return (
        <div>
            <p className='labelTop'>{t("questionDinner")}</p>
            <div className="buttonGroup">
                <Button className="enterGuestInfo-response-button" onClick={() => answer(true)}>{t("yesButton")}</Button>
                <Button className="enterGuestInfo-response-button" onClick={() => answer(false)}>{t("noButton")}</Button>
            </div>
        </div>
    );
};

const MealStep = () => {
    const { t } = useTranslation();
    const { invitation, setInvitation } = useContext(InvitationContext);
    const guestId = invitation.activeGuestId;
    const { nextStep } = useWizard();
    const guestInfo = invitation.guests.find(guest => guest.id === guestId);
    if (guestInfo.dinner === false || guestInfo.response.dinner === false) {
        nextStep();
    }
    const answer = (response) => {
        guestInfo.response.meal = response;
        const updatedGuests = invitation.guests.map(guest =>
            guest.id === guestId ? guestInfo : guest
        );
        setInvitation({ ...invitation, guests: updatedGuests });
        nextStep();
    };

    return (
        <div>
            <p className='labelTop'>{t("questionMeal")}</p>
            <div className="buttonGroup">
                <Button className="enterGuestInfo-response-button" onClick={() => answer("MEAT")}>{t("meatButton")}</Button>
                <Button className="enterGuestInfo-response-button" onClick={() => answer("VEGAN")}>{t("veganButton")}</Button>
                <Button className="enterGuestInfo-response-button" onClick={() => answer("CHILD")}>{t("childButton")}</Button>
            </div>
        </div>
    );
};

const BrunchStep = () => {
    const { t } = useTranslation();
    const { nextStep } = useWizard();
    const { invitation, setInvitation } = useContext(InvitationContext);
    const guestId = invitation.activeGuestId;

    const guestInfo = invitation.guests.find(guest => guest.id === guestId);
    if (guestInfo.brunch === false) {
        nextStep();
    }

    const answer = (response) => {
        guestInfo.response.brunch = response;
        const updatedGuests = invitation.guests.map(guest =>
            guest.id === guestId ? guestInfo : guest
        );
        setInvitation({ ...invitation, guests: updatedGuests });
        nextStep();
    };

    return (
        <div>
            <p className='labelTop'>{t("questionBrunch")}</p>
            <div className="buttonGroup">
                <Button className="enterGuestInfo-response-button" onClick={() => answer(true)}>{t("yesButton")}</Button>
                <Button className="enterGuestInfo-response-button" onClick={() => answer(false)}>{t("noButton")}</Button>
            </div>
        </div>
    );
};

const ValidateStep = () => {
    const { t } = useTranslation();
    const { invitation, setInvitation } = useContext(InvitationContext);
    const guestId = invitation.activeGuestId;

    const guestInfo = useRef(invitation.guests.find(guest => guest.id === guestId));


    useEffect(() => {
        guestInfo.current = invitation.guests.find(guest => guest.id === guestId);
    }, [invitation, guestId]);

    const handleChange = (field, value) => {
        guestInfo.current[field] = value;
        const updatedGuests = invitation.guests.map(guest =>
            guest.id === guestId ? guestInfo.current : guest
        );
        setInvitation({ ...invitation, guests: updatedGuests });
    };

    const handleResponseChange = (field, value) => {
        guestInfo.current.response[field] = value;
        const updatedGuests = invitation.guests.map(guest =>
            guest.id === guestId ? guestInfo.current : guest
        );
        setInvitation({ ...invitation, guests: updatedGuests });
    };

    return (
        <div className='validateStep'>
            <p className='labelTop'>{t("questionValidate")}</p>
            <div className='guestTable'>
                <table>
                    <thead>
                        <tr>
                            <th>{t("questionTableHeader")}</th>
                            <th>{t("answerTableHeader")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><label>{t("firstNameLabel")}:</label></td>
                            <td>
                                <input
                                    type="text"
                                    value={guestInfo.current.firstName}
                                    onChange={(e) => handleChange('firstName', e.target.value)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td><label>{t("lastNameLabel")}</label></td>
                            <td>
                                <input
                                    type="text"
                                    value={guestInfo.current.lastName}
                                    onChange={(e) => handleChange('lastName', e.target.value)}
                                />
                            </td>
                        </tr>
                        {guestInfo.current.type === "baby" && (
                            <>
                                <tr>
                                    <td><label>{t("babyStep.validationLabel")}</label></td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={guestInfo.current.response.ceremony}
                                            onChange={(e) => handleResponseChange('ceremony', e.target.checked)}
                                        />
                                    </td>
                                </tr>
                            </>
                        )}
                        {guestInfo.current.type !== "baby" && (
                            <>
                                <tr>
                                    <td><label>{t("emailLabel")}</label></td>
                                    <td>
                                        <input
                                            type="email"
                                            value={guestInfo.current.email}
                                            onChange={(e) => handleChange('email', e.target.value)}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td><label>{t("phoneLabel")}</label></td>
                                    <td>
                                        <input
                                            type="tel"
                                            value={guestInfo.current.phone}
                                            onChange={(e) => handleChange('phone', e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td><label>{t("ceremonyLabel")}</label></td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={guestInfo.current.response.ceremony}
                                            onChange={(e) => handleResponseChange('ceremony', e.target.checked)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td><label>{t("cocktailLabel")}</label></td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={guestInfo.current.response.cocktail}
                                            onChange={(e) => handleResponseChange('cocktail', e.target.checked)}
                                        />
                                    </td>
                                </tr>
                                {guestInfo.current.dinner === true && (
                                    <tr>
                                        <td><label>{t("dinnerLabel")}</label></td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={guestInfo.current.response.dinner}
                                                onChange={(e) => handleResponseChange('dinner', e.target.checked)}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {(guestInfo.current.dinner === true) && (
                                    <tr>
                                        <td><label>{t("mealLabel")}</label></td>
                                        <td>
                                            <select
                                                value={guestInfo.current.response.meal}
                                                onChange={(e) => handleResponseChange('meal', e.target.value)}
                                            >
                                                <option value="MEAT">{t("meat")}</option>
                                                <option value="VEGAN">{t("vegan")}</option>
                                                <option value="CHILD">{t("child")}</option>
                                                <option value="NA">{t("NA")}</option>
                                            </select>
                                        </td>
                                    </tr>
                                )}
                                {guestInfo.current.brunch === true && (
                                    <tr>
                                        <td><label>{t("brunchLabel")}</label></td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={guestInfo.current.response.brunch}
                                                onChange={(e) => handleResponseChange('brunch', e.target.checked)}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </>
                        )}

                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default EnterGuestInfoForm;